import React, { FC, useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';

import { FaqAccordionItemProps } from './models';

import './FaqAccordionItem.scss';

const FaqAccordionItem: FC<FaqAccordionItemProps> = ({
  questionTitle,
  question,
  answer,
  faqTypeVariant,
  eventKey,
}) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey);

  const isCurrentEventKey = currentEventKey === eventKey;

  const faqAccordionContentClassNames = classNames('faq-accordion-item__header-content', {
    [`opened`]: isCurrentEventKey,
  });

  return (
    <div
      data-testid="faq-accordion-item"
      className={classNames('faq-accordion-item', {
        [`faq-accordion-item--${faqTypeVariant}`]: faqTypeVariant,
      })}
    >
      <Card className="faq-accordion-item__card">
        <Card.Header className="faq-accordion-item__card-header">
          <button
            className="faq-accordion-item__header"
            onClick={decoratedOnClick}
            tabIndex={0}
            type="button"
          >
            {questionTitle ? (
              <span className={faqAccordionContentClassNames}>
                <div className="faq-accordion-item__header-question">
                  <DangerouslySetInnerHtml
                    html={questionTitle}
                    className="faq-accordion-item__header-question-title"
                    data-testid="faq-accordion-item-question-title"
                  />
                  <div className="faq-accordion-item__header-question-wrapper">
                    <i className="faq-accordion-item__header-indicator">
                      {isCurrentEventKey ? '-' : '+'}
                    </i>
                    <DangerouslySetInnerHtml
                      html={question}
                      data-testid="faq-accordion-item-question"
                    />
                  </div>
                </div>
              </span>
            ) : (
              <span className={faqAccordionContentClassNames}>
                <i className="faq-accordion-item__header-indicator">
                  {isCurrentEventKey ? '-' : '+'}
                </i>
                <div className="faq-accordion-item__header-question">
                  <DangerouslySetInnerHtml
                    html={question}
                    data-testid="faq-accordion-item-question"
                  />
                </div>
              </span>
            )}
          </button>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body className="faq-accordion-item__card-body">
            <DangerouslySetInnerHtml
              html={answer}
              className="faq-accordion-item__header-answer"
              data-testid="faq-accordion-item-answer"
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
};

export default FaqAccordionItem;
