import React, { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

import { VisualType, VisualVariant } from 'common/Button';
import LinkButton from 'components/LinkButton';

import FaqAccordionItem from './FaqAccordionItem';
import { FaqProps } from './models';

import './Faq.scss';

const Faq: FC<FaqProps> = ({ faqItems, ctaLink, ctaAriaLabel, title, faqType }) => {
  const cta = ctaLink ? ctaLink[0] : null;
  const faqTypeVariant = faqType?.[0];

  return (
    <Row
      data-testid="faq"
      className={classNames('faq', {
        [`faq--${faqTypeVariant}`]: faqTypeVariant,
      })}
    >
      <Col>
        <h2 className="faq__title">{title}</h2>
        {faqItems ? (
          <Accordion>
            {faqItems.map((faqElement, i) => (
              <FaqAccordionItem
                questionTitle={faqElement.questionTitle}
                question={faqElement.question}
                answer={faqElement.answer}
                faqTypeVariant={faqTypeVariant}
                eventKey={i.toString()}
                key={faqElement.question}
              />
            ))}
          </Accordion>
        ) : null}
        {cta ? (
          <div className="faq__cta-link" data-testid="faq-cta-link">
            <LinkButton
              text={cta.name}
              type={VisualType.Discover}
              url={cta.url}
              ariaLabel={ctaAriaLabel}
              variant={VisualVariant.Dark}
            />
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default Faq;
